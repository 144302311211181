import React from "react";
import { Map } from "./Map";
import "./Locations.css";

export const Locations: React.FC = () => {
  return (
    <div className="container locations">
      <hr></hr>
      <div className="row">
        <div className="col-md-7 align-self-center">
          <div className="text-container">
            <h2>Summer</h2>
            <p className="lead">
              In the summer we can be found at our beautiful outdoor range near
              Exbury Gardens. Club shoots are Sunday mornings, Wednesday mornings 
              and Thursday evenings. On any other day we can shoot any time light 
              allows (so long as there are at least 2 adult members present).
            </p>
          </div>
        </div>
        <div className="col-md-5">
          <Map location="Waterside%20Archers" />
        </div>
      </div>

      <hr></hr>

      <div className="row flex-column-reverse flex-md-row">
        <div className="col-md-5">
          <Map location="Gang%20Warily" />
        </div>
        <div className="col-md-7 align-self-center">
          <div className="text-container">
            <h2>Winter</h2>
            <p className="lead">
              In the winter we can be found hiding at our indoor range at Gang
              Warily on the following days:
            </p>
            <p className="font-weight-bold">Friday 6:45pm - 9pm</p>
            <p className="lead">
              When the weather allows, we will also be shooting at our
              outdoor range near Exbury Gardens on Sunday and Wednesday mornings.
              On any other day we can shoot any time light allows (so long as 
              there are at least 2 adult members present).
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
