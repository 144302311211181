import React from "react";
import Header from "../Common/Header";
import RecordsTabs from "./RecordsTabs";
import {Helmet} from "react-helmet";

const Records = () => {
  return (
    <>
      <Helmet>
        <title>Waterside Archers - Club Records</title>
        <meta name="description" content="Club records for the Waterside Archers, listing all the records for the different bow styles and archery rounds." />
      </Helmet>
      <Header text="Club Records" />
      <div className="container">
        <p className="lead">Club members can browse detailed club records from their <a href='https://archery-records.net/users/club_records' target="_blank" rel="noreferrer">Golden Records User Account.</a></p>
        <p className="lead">See all our records for the categories below:</p>
        <RecordsTabs />
        <p className="lead">
          <a href="https://haa.org.uk/records/competiton-records" target="_blank" rel="noreferrer">
            Click here to see club county records
          </a>
        </p>
        <p className="lead">
          <a href="https://worldarchery.org/world-records" target="_blank" rel="noreferrer">
            Click here to see world records
          </a>
        </p>
      </div>
    </>
  );
};

export default Records;
